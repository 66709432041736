import { useDispatch, useSelector } from 'react-redux';

/** @ts-ignore */
import { RootState } from 'StoreTypes';

// redux
import {
  getList as getCameraTests
} from '../redux/slices/cameraTest';

// ----------------------------------------------------------------------

export default function useCameraTest() {
  // Camera Test
  const dispatch = useDispatch();

  const { isLoading, cameraTests } = useSelector(
    (state: RootState) => state.cameraTest
  );

  // Camera Test
  return {
    method: 'cameraTest',
    isLoading,
    cameraTests,

    // @ts-ignore
    getCameraTests: () => dispatch(getCameraTests())
  };
}
