import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { ReactNode } from 'react';

// hooks
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

interface GuestProtectProps {
  children: ReactNode;
}

// AuthProtect.propTypes = {
//   children: PropTypes.node
// };

export default function AuthProtect({ children }) {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
}
