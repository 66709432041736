import axios from 'axios';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: '/api' });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => error
);

export default axiosInstance;
