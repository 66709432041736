import { useDispatch, useSelector } from 'react-redux';

/** @ts-ignore */
import { RootState } from 'StoreTypes';

// redux
import {
  getList as getMatchList
} from '../redux/slices/match';

// ----------------------------------------------------------------------

export default function useMatch() {
  // Match
  const dispatch = useDispatch();

  const { isLoading, matchList } = useSelector(
    (state: RootState) => state.match
  );

  // Match
  return {
    method: 'match',
    isLoading,
    matchList,

    // @ts-ignore
    getMatchList: () => dispatch(getMatchList())
  };
}
