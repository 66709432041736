import axios from 'src/utils/axios';

export const fetchImages = async () => {
  const config = {
    headers: {
      Accept: 'application/json'
    }
  };
  const response = await axios.get('/images/', config);

  return response;
};

export const uploadImages = async (payload) => {
  console.log(payload.image_name);
  const config = {
    headers: {
      Accept: 'application/json'
    }
  };
  // const response = await axios.post(`/images/${payload.cameraTestName}`, payload.image_name, config);
  const response = await axios.post(`/images/${payload.cameraTestName}/${payload.image_name}`, config);

  return response;
};

export const fetchImagesByCameraTestName = async (payload) => {
  const config = {
    headers: {
      Accept: 'application/json'
    }
  };
  const response = await axios.get(`/images/${payload.cameraTestName}`, config);

  return response;
};

export const deleteImagesByCameraTestName = async (payload) => {
  const config = {
    headers: {
      Accept: 'application/json'
    }
  };
  const response = await axios.delete(`/images/${payload.cameraTestName}`, config);

  return response;
};

export const fetchImageNamesByCamera = async (payload) => {
  const config = {
    headers: {
      Accept: 'application/json'
    }
  };
  const response = await axios.get(`/images/${payload.cameraName}/filenames`, config);

  return response;
};

export const fetchGridParamsByCameraTestName = async (payload) => {
  const config = {
    headers: {
      Accept: 'application/json'
    }
  };
  const response = await axios.get(`/data/${payload.cameraTestName}/grid_params`, config);

  return response;
};

export const saveGridParamsByCameraTestName = async (payload) => {
  const config = {
    headers: {
      Accept: 'application/json'
    }
  };
  const response = await axios.post(`/data/${payload.cameraTestName}/grid_params`, payload.gridParams, config);

  return response;
};

export const createTabularDataset = async (payload) => {
  const config = {
    headers: {
      Accept: 'application/json'
    }
  };
  const response = await axios.post(`/data/${payload.cameraTestName}`, null, config);

  return response;
};
