import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { ReactNode } from 'react';
// hooks
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

interface GuestProtectProps {
  children: ReactNode;
}

// GuestProtect.propTypes = {
//   children: PropTypes.node
// };

export default function GuestProtect({ children }) {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/dashboards" />;
  }

  return <>{children}</>;
}
