import axios from 'src/utils/axios';

export const create = async (payload) => {
  const config = {
    headers: {
      Accept: 'application/json'
    }
  };
  const response = await axios.post(`/ml/`, payload, config);

  return response;
};

export const get = async (payload) => {
  const config = {
    headers: {
      Accept: 'application/json'
    }
  };
  const response = await axios.get(`/LUT/${payload.matchName}`, config);

  return response;
};

export const status = async (payload) => {
  const config = {
    headers: {
      Accept: 'application/json'
    }
  };
  const response = await axios.get(`/ml/status/${payload}`, config);

  return response;
};

export const list = async () => {
  const config = {
    headers: {
      Accept: 'application/json'
    }
  };
  const response = await axios.get(`/LUT/`, config);

  return response;
};

export const remove = async (payload) => {
  const config = {
    headers: {
      Accept: 'application/json'
    }
  };
  const response = await axios.delete(`/LUT/${payload.matchName}`, config);

  return response;
};
