import { createSlice } from '@reduxjs/toolkit';
import { fetchImages as fetchCameraTests } from 'src/api/CameraTest';
import { pause } from 'src/utils/timer';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  cameraTests: []
};

const slice = createSlice({
  name: 'cameraTest',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // INITIALISE
    getListResult(state, action) {
      state.isLoading = false;
      state.cameraTests = action.payload.cameraTests;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    await pause();

    try {
      const result = await fetchCameraTests();
      if (result.status == 200) {
        dispatch(
          slice.actions.getListResult({
            cameraTests: result.data
          })
        );
      } else {
        dispatch(
          slice.actions.getListResult({
            cameraTests: []
          })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        slice.actions.getListResult({
          cameraTests: []
        })
      );
    }
  };
}
