import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

/** @ts-ignore */
import { RootState } from 'StoreTypes';

// redux
import {
  login,
  logout
} from '../redux/slices/authJwt';

// ----------------------------------------------------------------------

useAuth.propTypes = {
  method: PropTypes.oneOf(['jwt', 'firebase'])
};

export default function useAuth() {
  // JWT Auth
  const dispatch = useDispatch();

  const { user, isLoading, isAuthenticated, isFailed } = useSelector(
    (state: RootState) => state.authJwt
  );

  // JWT Auth
  return {
    method: 'jwt',
    user,
    isLoading,
    isAuthenticated,
    isFailed,

    login: ({ username, password }) =>
      dispatch(
        /** @ts-ignore */
        login({
          username,
          password
        })
      ),

    /** @ts-ignore */
    logout: () => dispatch(logout())
  };
}
