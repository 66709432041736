import PropTypes from 'prop-types';
import { useEffect, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
// redux
import { getInitialize } from '../redux/slices/authJwt';

// ----------------------------------------------------------------------

// JwtProvider.propTypes = {
//   children: PropTypes.node
// };

interface JwtProviderProps {
  children: ReactNode;
}

export default function JwtProvider({ children }) {
  const dispatch = useDispatch();

  useEffect(() => {
    /** @ts-ignore */
    dispatch(getInitialize());
  }, [dispatch]);

  return <>{children}</>;
}
