import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

import AuthGuard from './guards/AuthGuard';
import GuestGuard from './guards/GuestGuard';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Login = Loader(lazy(() => import('src/content/Login')));

// Home

const Home = Loader(lazy(() => import('src/content/Home')));

const CameraTestCreate = Loader(lazy(() => import('src/content/CameraTest/Create')));
const CameraTestEdit = Loader(lazy(() => import('src/content/CameraTest/Edit')));
const MatchCreate = Loader(lazy(() => import('src/content/Match/Create')));

interface RouteObject {
  caseSensitive?: boolean;
  children?: RouteObject[];
  element?: React.ReactNode;
  index?: boolean;
  path?: string;
  guard?: React.ReactNode;
}

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <GuestGuard><Login /></GuestGuard>
      },
      {
        path: 'login',
        element: <GuestGuard><Navigate to="/" replace /></GuestGuard>
      }
    ]
  },
  {
    path: 'dashboards',
    element: <AuthGuard><SidebarLayout /></AuthGuard>,
    children: [
      {
        path: '',
        element: <Navigate to="home" replace />
      },
      {
        path: 'home',
        element: <Home />
      }
    ]
  },
  {
    path: 'camera-test',
    element: <AuthGuard><SidebarLayout /></AuthGuard>,
    children: [
      {
        path: '',
        element: <Navigate to="create" replace />
      },
      {
        path: 'create',
        element: <CameraTestCreate />
      },
      {
        path: 'edit/:name',
        element: <CameraTestEdit />
      }
    ]
  },
  {
    path: 'match',
    element: <AuthGuard><SidebarLayout /></AuthGuard>,
    children: [
      {
        path: '',
        element: <Navigate to="create" replace />
      },
      {
        path: 'create',
        element: <MatchCreate />
      }
    ]
  }
];

export default routes;
