import { jwtDecode } from 'jwt-decode';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  isFailed: false,
  user: {}
};

const slice = createSlice({
  name: 'authJwts',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // INITIALISE
    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
    },

    // LOGIN
    loginSuccess(state, action) {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.isFailed = false;
      state.user = action.payload.user;
    },

    loginFail(state) {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.isFailed = true;
      state.user = null;
    },

    // LOGOUT
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = null;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

const setSession = ({ accessToken, user }) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }

  if (user) {
    localStorage.setItem('user', user);
  } else {
    localStorage.removeItem('user');
  }
};

// ----------------------------------------------------------------------

export function login({ username, password }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };
  
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    const response = await axios.post('/token', params, config);

    if (response.status == 200) {
      const { access_token } = response.data;
      setSession({ accessToken: access_token, user: username });
      dispatch(slice.actions.loginSuccess({ user: username }));
      // @ts-ignore
    } else if (response.code == 'ERR_BAD_REQUEST') {
      dispatch(slice.actions.loginFail());
    } else {
      // @ts-ignore
      console.log(response.code);
    }
  };
}

// ----------------------------------------------------------------------

export function logout() {
  return async (dispatch) => {
    setSession({ accessToken: null, user: null });
    dispatch(slice.actions.logoutSuccess());
  };
}

// ----------------------------------------------------------------------

export function getInitialize() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const accessToken = window.localStorage.getItem('accessToken');
      const user = window.localStorage.getItem('user');
      if (accessToken && isValidToken(accessToken)) {
        setSession({ accessToken, user });
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: true,
            user: user
          })
        );
      } else {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: false,
            user: null
          })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null
        })
      );
    }
  };
}
