import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import JwtProvider from './providers/JwtProvider';

import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

// redux
import { store, persistor } from './redux/store';

function App() {

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ReduxProvider store={store}>
          <PersistGate persistor={persistor}>
            <CssBaseline />
            <JwtProvider>
              {useRoutes(router)}
            </JwtProvider>
          </PersistGate>
        </ReduxProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
