import { createSlice } from '@reduxjs/toolkit';
import { list as fetchMatchList } from 'src/api/Match';
import { pause } from 'src/utils/timer';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  matchList: []
};

const slice = createSlice({
  name: 'match',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // INITIALISE
    getListResult(state, action) {
      state.isLoading = false;
      state.matchList = action.payload.matchList;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    await pause();

    try {
      const result = await fetchMatchList();
      if (result.status == 200) {
        dispatch(
          slice.actions.getListResult({
            matchList: result.data
          })
        );
      } else {
        dispatch(
          slice.actions.getListResult({
            matchList: []
          })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        slice.actions.getListResult({
          matchList: []
        })
      );
    }
  };
}
